import { addUserServiceApi } from '@/api/user';
<template>
  <div class="my-balance">
    <div class="b-title">我的余额</div>
    <ul class="b-money">
      <li class="m-item">
        账户可用余额(元)
        <span>0.00</span>
      </li>
      <li class="m-item">
        累计充值(元)：
        <span>0.00</span>
      </li>
      <li class="m-item">
        累计消费(元)：
        <span>0.00</span>
      </li>
    </ul>
    <div class="b-detail">
      <div class="d-hd">
        <ul class="h-title">
          <li class="t-item t-current">近三个月收支明细</li>
          <li class="t-item">三个月前收支明细</li>
        </ul>
        <div class="h-history">
          <a href="#">历史充值记录</a>
        </div>
      </div>
      <div class="d-bd">
        <!-- 暂无账单明细 -->
        <div class="b-nobill" v-if="isShowBill">
          <img src="../../../assets/images/nobill.png" alt="" />
          <div class="b-txt">您还没有任何收支明细记录哦</div>
        </div>
        <table class="b-table">
          <thead class="t-hd">
            <th>时间</th>
            <th>金额</th>
            <th>操作</th>
            <th>备注</th>
          </thead>
          <tbody class="t-bd">
            <td>2021.11.16</td>
            <td>2021.11.16</td>
            <td>2021.11.16</td>
            <td>2021.11.16</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name:"myBalance",
       data(){
           return{
            //是否有账单明细
               isShowBill:false
           }
       }
        }
</script>

<style lang="less" scoped>
.my-balance {
  padding: 10px 40px;

  .b-title {
    height: 60px;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    line-height: 60px;
  }
  .b-money {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    .m-item {
      padding-right: 200px;
      font-size: 14px;
      color: #969696;
      span {
        display: block;
        margin-top: 10 px;
        color: #2b1414;
        font-size: 32px;
      }
    }
  }
  .b-detail {
    .d-hd {
      height: 30px;
      border-bottom: 2px solid #ddd;
      .h-title {
        float: left;
        .t-item {
          width: 150px;
          text-align: center;
          float: left;
          line-height: 30px;
          margin-right: 20px;
          font-weight: 600;
          font-size: 14px;
          color: #666;
        }
        .t-current {
          border-bottom: 2px solid #e1251b;
        }
      }
      .h-history {
        float: right;
      }
    }
    .d-bd {
      text-align: center;
      color: #666;
      font-size: 14px;
      position: relative;
      min-height: 500px;
      .b-nobill {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 224px;
        height: 142px;
        margin-bottom: 20px;
        img {
          width: 224px;
          height: 142px;
        }
      }
      .b-table {
        border: 1px solid #e1251b;
        margin-top: 10px;
        .t-hd {
          height: 40px;
          background: #f5f5f5;
        }
        .t-bd {
        }
      }
    }
  }
}
</style>